var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-12"},[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("RegisterFormHeader"))+" ")])],1),_c('v-card-text',[_c('v-form',{ref:"register-form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-account","name":"name","label":_vm.$t('FirstName'),"type":"text","color":"dark","rules":[_vm.filterRules.required]},model:{value:(_vm.credentials.name),callback:function ($$v) {_vm.$set(_vm.credentials, "name", $$v)},expression:"credentials.name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-account","name":"name","label":_vm.$t('LastName'),"type":"text","color":"dark","rules":[_vm.filterRules.required]},model:{value:(_vm.credentials.surname),callback:function ($$v) {_vm.$set(_vm.credentials, "surname", $$v)},expression:"credentials.surname"}})],1),_c('v-col',{attrs:{"cols":"12","dir":"ltr"}},[_c('country-code-phone-input',{attrs:{"field-attrs":{
              appendIcon: 'mdi-phone',
              label: _vm.$t('Phone'),
              solo: false,
              rules: [_vm.filterRules.required],
              color: 'dark',
              value: _vm.credentials.phone,
            },"autocomplete-attrs":{
              solo: false,
              rules: [_vm.filterRules.required],
              color: 'dark',
              class: 'mt-1',
            }},on:{"phone-changed":_vm.changedPhone,"code-phone-changed":_vm.codePhoneChange}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-email","name":"email","label":_vm.$t('Email'),"type":"text","color":"dark","rules":[_vm.filterRules.email, _vm.filterRules.required]},model:{value:(_vm.credentials.email),callback:function ($$v) {_vm.$set(_vm.credentials, "email", $$v)},expression:"credentials.email"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-lock","name":"password","label":_vm.$t('Password'),"type":"password","color":"dark","rules":[_vm.filterRules.required, _vm.filterRules.min8]},model:{value:(_vm.credentials.password),callback:function ($$v) {_vm.$set(_vm.credentials, "password", $$v)},expression:"credentials.password"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-lock","name":"password_confirmation","label":_vm.$t('PasswordConfirmation'),"type":"password","color":"dark","rules":[
              _vm.filterRules.required,
              _vm.filterRules.passwordConfirmation,
              _vm.filterRules.min8 ]},model:{value:(_vm.credentials.password_confirmation),callback:function ($$v) {_vm.$set(_vm.credentials, "password_confirmation", $$v)},expression:"credentials.password_confirmation"}})],1)],1),_c('div',{staticClass:"register-actions"},[_c('div',{staticClass:"register-captcha"},[_c('vue-recaptcha',{attrs:{"loadRecaptchaScript":true,"sitekey":_vm.captchaSiteKey},on:{"verify":function($event){_vm.allowSubmit  = true},"error":function($event){_vm.allowSubmit   = false}}})],1),_c('v-btn',{staticClass:"register-btn ",attrs:{"disabled":!_vm.allowSubmit,"loading":_vm.isRegistering,"color":"black"},on:{"click":_vm.attemptRegister}},[_vm._v(" "+_vm._s(_vm.$t("Signup"))+" ")]),_c('a',{staticClass:"login-link",on:{"click":function($event){return _vm.goTo('LoginPage')}}},[_vm._v(" "+_vm._s(_vm.$t("LoginHere"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }