<template>
    <div>
        <v-container fluid class="register-page" fill-height>
            <v-row no-gutters justify="center" align="center">
                <v-col cols="12" md="6">
                    <register-form 
                        @successfull-register="attemptingRegister"
                    />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>

import Vue                  from 'vue';
import RegisterForm         from "../../components/Form/Auth/Register";
import PolygonBackground    from "../../components/Util/PolygonBackground";

export default {
    name: "LoginPage",
    components: {
        RegisterForm,
        PolygonBackground
    },
    data() {
        return {
            animatePolygons: false
        }
    },
    methods: {
        attemptingRegister() {
            this.animatePolygons = true;
            setTimeout(() => {
                this.$router.go(-1);
            }, 2000);
        },
        goTo(routeName) {
            var searchObj = Vue.urlHelper.extractQuery();
            if (searchObj["company_id"]) {
                this.$router.push({name: routeName, query: {company_id: searchObj["company_id"]}});
            } else {
                this.$router.push({name: routeName});
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.register-page {
    height: 80vh;
}
</style>
