<template>
  <v-card class="elevation-12">
    <v-toolbar dark >
      <v-toolbar-title> {{ $t("RegisterFormHeader") }} </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form v-model="valid" ref="register-form" lazy-validation>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="credentials.name"
              prepend-icon="mdi-account"
              name="name"
              :label="$t('FirstName')"
              type="text"
              color="dark"
              :rules="[filterRules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="credentials.surname"
              prepend-icon="mdi-account"
              name="name"
              :label="$t('LastName')"
              type="text"
              color="dark"
              :rules="[filterRules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" dir="ltr">
            <country-code-phone-input
              :field-attrs="{
                appendIcon: 'mdi-phone',
                label: $t('Phone'),
                solo: false,
                rules: [filterRules.required],
                color: 'dark',
                value: credentials.phone,
              }"
              :autocomplete-attrs="{
                solo: false,
                rules: [filterRules.required],
                color: 'dark',
                class: 'mt-1',
              }"
              @phone-changed="changedPhone"
              @code-phone-changed="codePhoneChange"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="credentials.email"
              prepend-icon="mdi-email"
              name="email"
              :label="$t('Email')"
              type="text"
              color="dark"
              :rules="[filterRules.email, filterRules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="credentials.password"
              prepend-icon="mdi-lock"
              name="password"
              :label="$t('Password')"
              type="password"
              color="dark"
              :rules="[filterRules.required, filterRules.min8]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="credentials.password_confirmation"
              prepend-icon="mdi-lock"
              name="password_confirmation"
              :label="$t('PasswordConfirmation')"
              type="password"
              color="dark"
              :rules="[
                filterRules.required,
                filterRules.passwordConfirmation,
                filterRules.min8,
              ]"
            ></v-text-field>
          </v-col>
        </v-row>
        <div class="register-actions">
          <div class="register-captcha">
            <vue-recaptcha
              :loadRecaptchaScript="true"
              @verify="allowSubmit  = true"
              @error="allowSubmit   = false"
              :sitekey="captchaSiteKey"
            ></vue-recaptcha>
          </div>
          <v-btn
            :disabled="!allowSubmit"
            :loading="isRegistering"
            class="register-btn "
            color="black"
            @click="attemptRegister"
          >
            {{ $t("Signup") }}
          </v-btn>
          <a class="login-link" @click="goTo('LoginPage')">
            {{ $t("LoginHere") }}
          </a>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue                    from "vue";
import CountryCodePhoneInput  from "../../Form/CountryCodePhoneInput";
import VueRecaptcha           from 'vue-recaptcha';
import { mapGetters }         from "vuex";

export default {
  name: "RegisterForm",
  components: {
    CountryCodePhoneInput,
    VueRecaptcha,
  },
  data() {
    return {
      valid:          false,
      isRegistering:  false,
      allowSubmit:    false,
      captchaSiteKey: Vue.recaptcha.siteKey,
      credentials: {
        name: null,
        surname: null,
        phone: null,
        email: null,
        password: null,
        password_confirmation: null,
      },
    };
  },
  computed: {
    filterRules() {
      let rules = Vue.formHelper.filterRules();
      return {
        ...rules,
        passwordConfirmation: (value) => {
          if (value && value !== this.credentials.password) {
            return this.$t("PasswordDontMatch");
          }
          return true;
        },
      };
    },
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
    }),
  },
  methods: {
    attemptRegister() {
      // to validate if none of the inputs were changed
      let validation = this.$refs["register-form"].validate();

      if (this.valid && validation) {
        this.$emit("attempting-register", true);
        this.isRegistering = true;

        this.$store
          .dispatch("auth/register", this.credentials)
          .then((success) => {
            this.$emit("successfull-register", true);
          })
          .catch((failure) => {
            if (failure.response) {
              this.$root.$emit(
                "notify-error",
                this.$t(failure.response.data.error.message)
              );
            }

            this.$emit("failure-register", true);

            setTimeout(() => {
              this.isRegistering = false;
            }, 500);
          });

        setTimeout(() => {
          this.isRegistering = false;
        }, 500);
      }
    },
    changedPhone(value) {
      this.credentials.phone = value;
    },
    codePhoneChange(value) {
      this.credentials.phone_country_code = value;
    },
    goTo(routeName) {
        var searchObj = Vue.urlHelper.extractQuery();
        if (searchObj["company_id"]) {
          this.$router.push({name: routeName, query: {company_id: searchObj["company_id"]}});
        } else {
          this.$router.push({name: routeName});
        }
    }
  },
  created() {
    if (this.isLoggedIn) {
      this.goTo('Account');
    }
  },
};
</script>

<style lang="scss" scoped>
.register-btn {
  display: block;
  margin: 10px auto;
  padding: 5px 20px;
}
.register-captcha {
  display: flex;
  justify-content: center;
  margin: 10px auto;
}
.login-link {
  text-decoration: none;
  font-weight: bold;
  margin: 10px auto;
}
.register-actions {
  text-align: center;
}
</style>